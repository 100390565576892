export class Caixa {
    EMP :string;
    ID_CAIXA :string;
    ID_MOV_RJ :string;
    VALOR_TARIFA :string;
    VALOR_SEGURO :string;
    VALOR_PEDAGIO :string;
    VALOR_TX :string;
    VALOR_DESCONTOS:string;
    INTEG_VALOR_TARIFA:string;
    INTEG_VALOR_SEGURO:string;
    INTEG_VALOR_TX:string;
    INTEG_VALOR_PEDAGIO:string;
    INTEG_VALOR_DESCONTOS:string;
    USUARIO_SRVP :string;
    DATA: string;
    QUANT :string; 
    GRUPO_P :string;
    ORGAO :string;  
    DEVOLUCAO :string; 
    CANCELADO :string;
    ID_BFI:string;
    D_GRUPO_P :string;
    D_ORGAO :string;
    TOTAL : number; 
 }