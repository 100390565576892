<div class="container-fluid master">
  <div class="card card-container">
    <p class="vRed">v.{{this.versao}}</p>
    <img src="{{this.vLogo}}" class="rounded mx-auto d-block imgLogo" alt="Responsive image">
    <p class="namePortalCard ">Sistema - NGS</p>
    <form class="form-signin">
      <div class="form-row">
        <div class="form-gropup col-lg-12">
          <label for="iRegistro">Usuário:</label>
          <input type="text" class="form-control form-control-sm" id="iRegistro" name="inpUser" [(ngModel)]="inpUser" >
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <label for="iSenha">Senha:</label>
          <input type="password" class="form-control form-control-sm" id="iSenha" name="inpSenha"
            [(ngModel)]="inpSenha">
        </div>
      </div>
    </form>
    <button type="submit" class="btn btn-secondary btn-sm" (click)="login()">ENTRAR</button>
    <div class="main-content" [hidden]="!datasnap.AlertLogin">
      <div class="alert {{datasnap.typeAlert}}" role="alert" data-dismiss="alerta">
        <p><b>{{datasnap.textAlert}}</b></p>
      </div>
    </div>
    <p class="empDev">&#169; {{this.ano}} NGS Nova Geração em Sistemas</p>
  </div>
</div>