import { Component, OnInit } from '@angular/core';
import { DatasnapProvider } from 'src/provider/datasnap';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Usuario } from 'src/models/usuario';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RetornoRel } from 'src/models/retornoRel';
import { CAIXAFECHADO } from 'src/models/caixaFechado';
import { CODAGENCIA } from 'src/models/codAgencia';



@Component({
  selector: 'app-caixa-fechado',
  templateUrl: './caixa-fechado.component.html',
  styleUrls: ['./caixa-fechado.component.css']
})
export class CaixaFechadoComponent implements OnInit {
  empresa: string;
  usuarioLogado: Usuario;
  usuario: string;
  registro: string;
  vCaixaFechado: Array<CAIXAFECHADO>;
  vCodigoAgencia: Array<CODAGENCIA>;
  inpDataF: string;
  inpDataI: string;
  inpCodAgAgencia: string;
  inpCodAgNome: string;

  vBaseUrl: string = environment.config.conexao.baseUrl;

  constructor(private datasnap: DatasnapProvider, private route: ActivatedRoute, private router: Router) {
    this.empresa = environment.config.empresa.empresa;
    this.usuarioLogado = this.datasnap.getUsuarioLogado();
    this.usuario = this.usuarioLogado.USUARIO;
    this.registro = this.usuarioLogado.REGISTRO;
    newCaixaFechado: CAIXAFECHADO;

    let queryAg = 'AGENCIA';
    let pQueryAg = '{"&I_EMP": "' + this.empresa + '" , "&S_USUARIO": "' + this.usuarioLogado.USUARIO + '"}';

  if (this.usuarioLogado.AGENCIA != null && this.usuarioLogado.AGENCIA != undefined) {
    this.inpCodAgAgencia = this.usuarioLogado.AGENCIA;
  }
  this.datasnap.getSQL3(this.empresa, queryAg, pQueryAg).subscribe((data: string[][]) => {
    this.vCodigoAgencia = data['result'];
  }); 


  }

  ngOnInit() {
  }

  carregaCaixaFechado(DataI: string, DataF: string) {
    let queryCaixaFechado = 'CAIXA_FECHADO';
    let vDatai = encodeURIComponent(this.datasnap.getConverteDt(DataI));
    let vDataf = encodeURIComponent(this.datasnap.getConverteDt(DataF))
    let paramCX = '{"&I_EMP": "' + this.empresa + '", "&I_AGENCIA": "' + this.inpCodAgAgencia + '", "&D-DATAI": "' + vDatai + '", "&D-DATAF": "' + vDataf + '"}';
    this.datasnap.getSQL2(this.empresa, queryCaixaFechado, paramCX).subscribe((data: string[][]) => {
      this.vCaixaFechado = data['result'];
      if (data != null || data != undefined) {
        this.vCaixaFechado.forEach(e => {

        })

        if (this.vCaixaFechado.length > 0) {
          this.datasnap.openLoading(false);
        }
      }
    });

  }
  getAgencia(temp: string) {
    this.vCodigoAgencia.forEach(v => {
      if (v.AGENCIA == temp) {
        this.inpCodAgNome = v.NOME;
      }
    });
  }


  printPdf(idCaixa: string) {
    if (idCaixa != '' && idCaixa != null && idCaixa != undefined) {
      this.datasnap.onPrintPdf(this.usuarioLogado.EMP, this.usuarioLogado.USUARIO, idCaixa);
    }
  }
}
