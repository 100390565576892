import { Component, OnInit } from '@angular/core';
import { DatasnapProvider } from 'src/provider/datasnap';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Caixa } from 'src/models/caixa';
import { ENCOMENDAS } from 'src/models/encomendas';
import { Total } from 'src/models/total';
import { DG0080 } from 'src/models/dg0080';
import { DF0818 } from 'src/models/df0818';
import { CODAGENCIA } from 'src/models/codAgencia';
import { OUTROMOVIMENTO } from 'src/models/outroMovimento';
import { BANCO } from 'src/models/banco';
import { MOVBANCO } from 'src/models/movBanco';
import { MOVREQUISICAO } from 'src/models/movRequisicao';
import { SUBTOTAL } from 'src/models/subTotal';
import { SALDOCAIXA } from 'src/models/saldoCaixa';
import { MOVSALDOCAIXA } from 'src/models/movSaldoCaixa';
import { ULTIMOIDCAIXA } from 'src/models/ultimoIdCaixa';
import { QDS0748 } from 'src/models/qds0748';
import { Usuario } from 'src/models/usuario';
import { qMaster } from 'src/models/qMaster';
import { ListaRequisicao } from 'src/models/ListaRequisicao';
import { CaixaMov } from 'src/models/caixaMov';
import { Retorno } from 'src/models/retorno';
import { THIS_EXPR, ThrowStmt } from '@angular/compiler/src/output/output_ast';
import { QDG85 } from 'src/models/QDG85';
import { RetornoRel } from 'src/models/retornoRel';
import { IMPORTADORJ } from 'src/models/importadoRJ';
//import { runInThisContext } from 'vm';

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.css']
})
export class HomeComponent implements OnInit {
  vBaseUrl: string = environment.config.conexao.baseUrl;
  empresa: string;
  usuarioLogado: Usuario;
  usuario: string;
  registro: string;
  id_caixa: number = 0;
  nextIdCaixa: number = 0;
  vCaixa: Array<Caixa>;
  vCaixaMov: Array<CaixaMov> = [];
  vUltimoIdCaixa: Array<ULTIMOIDCAIXA>;
  vTotal: Array<Total>;
  vDF818: Array<DF0818>;
  vCodigoAgencia: Array<CODAGENCIA>;
  vOutrosMov: Array<OUTROMOVIMENTO> = [];
  vBanco: Array<BANCO>;
  vSubTotal: Array<SUBTOTAL> = [];
  vRequisicao: Array<MOVREQUISICAO> = [];
  vListaRequisicao: Array<ListaRequisicao>;
  vImportadoRJ: Array<IMPORTADORJ> = [];
  vEncomendas: Array<ENCOMENDAS> = [];
  vSaldoCaixa: Array<SALDOCAIXA>;
  vMovSaldoCaixa: Array<MOVSALDOCAIXA> = [];
  vBancoMov: Array<MOVBANCO> = [];
  timer: Array<DG0080>;
  varTimer: number;
  totOutMov: number = 0;
  totBancoMov: number = 0;
  totRequisicaoMov: number = 0;
  vlrSaldoCaixa: number = 0;
  tTarifa: number = 0;
  tSeguro: number = 0;
  tPedagio: number = 0;
  tTaxa: number = 0;
  tQuant: number = 0;
  tCancelado: number = 0;
  TDevolucao: number = 0;
  tIdMov_RJ: number = 0;
  totalPassagens: number = 0;
  totalLiquido: number = 0;
  somaConhec: number = 0;
  saldoAnterior: number = 0;
  inpSelMov: string;
  inpObsMov: string;
  inpDataMov: string;
  inpDataCaixa: string;
  inpDocMov: string;
  inpComenMov: string;
  inpValorMov: number;
  inpTipoMov: string;
  inpBancoAG: string;
  inpBancoBC: string;
  inpBancoConta: string;
  inpBancoID: string;
  inpBancoValor: string;
  inpBancoData: string;
  inBancoBC: string;
  inpCodAgAgencia: string;
  inpCodAgNome: string;
  inpRequisicaoData: string;
  inpRequisicaoCNPJ: string;
  inpRequisicaoValor: string;
  inpRequisicaoNumero: string;
  inpRequisicaoNome: string;
  inpRequisicaoObs: string;
  inpAgenciaCaixa: string;
  inpTotal: string;
  vSeq: number = 0;

  newOutMov: OUTROMOVIMENTO;
  newImportadoRJ : IMPORTADORJ;
  newMovBanco: MOVBANCO;
  newRequisao: MOVREQUISICAO;
  newMovSaldoCaixa: MOVSALDOCAIXA;
  newEncomendas: ENCOMENDAS;
  newCaixa: Caixa;
  tstErro: boolean = false;
  Qmaster: qMaster;
  vDG85: Array<QDG85>;
  valResultado: number;

  constructor(private datasnap: DatasnapProvider, private route: ActivatedRoute, private router: Router) {
    this.empresa = environment.config.empresa.empresa;
    this.usuarioLogado = this.datasnap.getUsuarioLogado();
    this.usuario = this.usuarioLogado.USUARIO;
    this.registro = this.usuarioLogado.REGISTRO;
    
    if (this.usuarioLogado.AGENCIA != null && this.usuarioLogado.AGENCIA != undefined) {
      this.inpCodAgAgencia = this.usuarioLogado.AGENCIA;
    }

    this.Qmaster = new qMaster();

    this.newImportadoRJ = new IMPORTADORJ();
    this.newOutMov = new OUTROMOVIMENTO();
    this.newMovBanco = new MOVBANCO();
    this.newRequisao = new MOVREQUISICAO();
    this.newEncomendas = new ENCOMENDAS();
    this.newMovSaldoCaixa = new MOVSALDOCAIXA();
    this.newCaixa = new Caixa();

   

    if (this.usuario != null || this.usuario != undefined) {
      let query = 'MOVIMENTO_CAIXA';
      let pQuery = '';
      this.datasnap.getSQL2(this.empresa, query, pQuery).subscribe((data: string[][]) => {
        this.vDF818 = data['result'];
      });

      let queryAg = 'AGENCIA';
      let pQueryAg = '{"&I_EMP": "' + this.empresa + '" , "&S_USUARIO": "' + this.usuarioLogado.USUARIO + '"}';

      this.datasnap.getSQL3(this.empresa, queryAg, pQueryAg).subscribe((data: string[][]) => {
        this.vCodigoAgencia = data['result'];
      }); 

      let queryBanco = 'BANCOS';

      this.datasnap.getSQL2(this.empresa, queryBanco, pQuery).subscribe((data: string[][]) => {
        this.vBanco = data['result'];
      });

      
      let queryRequisicao = 'REQUISICAO';

      this.datasnap.getSQL2(this.empresa, queryRequisicao, pQuery).subscribe((data: string[][]) => {
        this.vListaRequisicao = data['result'];

      });



    } else {
      this.router.navigate(['/Login']);
    }
  }

  carregaSaldoCaixa(Agencia: string){
    let qSaldoCaixa = 'SALDO_CAIXA';
    let param2 = '{"&I_EMP": "' + this.empresa + '" , "&I_AGENCIA": "' + this.inpCodAgAgencia + '"}';

    this.datasnap.getSQL3(this.empresa, qSaldoCaixa, param2).subscribe((data: string[][]) => {
      this.vSaldoCaixa = data['result'];
      this.vlrSaldoCaixa = this.vSaldoCaixa[0].SALDO;

      if (this.vSaldoCaixa.length > 0) {
        this.datasnap.openLoading(false);
      }
    });
  }

  carregaCaixa(DataCaixa: string) {
    
    console.log('passou aqui!!!!');
    console.log(this.inpCodAgAgencia);

    if (this.inpCodAgAgencia != null || this.inpCodAgAgencia != undefined) { 
       
    let titulo = 'CAIXA';
    let param = '{"&I_EMP": "' + this.empresa + '", "&I_AGENCIA": "' + this.inpCodAgAgencia + '", "&D_DATA": "' + this.datasnap.getConverteDt(DataCaixa) + '"}';
    this.tTarifa = 0;
    this.tSeguro = 0;
    this.tTaxa = 0;
    this.tPedagio = 0;
    this.tCancelado = 0;
    this.TDevolucao = 0;
    this.tQuant = 0;
    this.totalPassagens = 0;

    this.vCaixa = [];
    this.datasnap.getSQL3(this.empresa, titulo, encodeURIComponent(param)).subscribe((data: string[][]) => {
      if (data != null || data != undefined) {
        this.vCaixa = data['result'];
        this.vCaixa.forEach(c => {
          c.TOTAL = parseFloat(c.VALOR_TARIFA) + parseFloat(c.VALOR_SEGURO) + parseFloat(c.VALOR_PEDAGIO) + parseFloat(c.VALOR_TX) - parseFloat(c.CANCELADO) - parseFloat(c.DEVOLUCAO);
          this.tTarifa = this.tTarifa + parseFloat(c.VALOR_TARIFA);
          this.tSeguro = this.tSeguro + parseFloat(c.VALOR_SEGURO);
          this.tTaxa = this.tTaxa + parseFloat(c.VALOR_TX);
          this.tPedagio = this.tPedagio + parseFloat(c.VALOR_PEDAGIO);
          this.tCancelado = this.tCancelado + parseFloat(c.CANCELADO);
          this.TDevolucao = this.TDevolucao + parseFloat(c.DEVOLUCAO);
          this.tQuant = this.tQuant + parseFloat(c.QUANT);
          this.totalPassagens = this.totalPassagens + c.TOTAL;
        })

        this.totalLiquido = this.totalPassagens;
      }
      if (this.vCaixa.length > 0) {
        this.datasnap.openLoading(false);
      
      }
    //  this.carregaEncomendas(DataCaixa);
      this.carregaOutrosMovimentos(DataCaixa);
      this.carregaSaldoCaixa(this.inpCodAgAgencia);
    });

  }  
  }
  carregaEncomendas(DataCaixa: string){
     this.vEncomendas = [];
    let titulo = 'ENCOMENDAS';
    let param = '{"&I_EMP": "' + this.empresa + '", "&I_AGENCIA": "' + this.inpCodAgAgencia + '", "&S_DATA": "' + this.datasnap.getConverteDt(DataCaixa) + '"}';
       this.datasnap.getSQL3(this.empresa, titulo, encodeURIComponent(param)).subscribe((data: string[][]) => {
      this.vEncomendas = data['result'];
         this.vEncomendas.forEach(e => {
          this.somaConhec = this.somaConhec + e.TOTAL;
         })
  
        if (this.vEncomendas.length > 0) {
          this.datasnap.openLoading(false);
        }
      });



  }

  carregaOutrosMovimentos(DataCaixa: string) {
    this.vOutrosMov = [] ;
    this.vImportadoRJ = [] ;
    let titulo = 'IMPORTADO_RJ';
    let param = '{"&I_EMP": "' + this.empresa + '", "&I_AGENCIA": "' + this.inpCodAgAgencia + '", "&D_DATA": "' + this.datasnap.getConverteDt(DataCaixa) + '"}';

    this.datasnap.getSQL3(this.empresa, titulo, encodeURIComponent(param)).subscribe((data: string[][]) => {
      if (data != null || data != undefined) {
        this.vImportadoRJ = data['result'];
        this.vImportadoRJ.forEach(c => {
        if (c.OPERACAO == 'E') {
          c.VALOR = c.VALOR * -1;
          }
          this.vSeq = 0;
          if (this.vOutrosMov.length == 0) {
            this.newOutMov.SEQ_LCTOS = (this.vSeq + 1).toString();
          } else if (this.vOutrosMov.length > 0) {
            this.vOutrosMov.forEach(p => {
              this.vSeq = Math.max(parseInt(p.SEQ_LCTOS));
              this.newOutMov.SEQ_LCTOS = (this.vSeq + 1).toString();
            });
          }

          this.somaValores(1, this.vOutrosMov);
          if (c.EMP != null || c.EMP != undefined) { 
          this.vOutrosMov.push({ EMP: c.EMP, ID_CAIXA: '0', SEQ_LCTOS: this.newOutMov.SEQ_LCTOS, CODIGO: c.CODIGO, VALOR: c.VALOR, DATA: c.DATA, DOCUMENTO: '', INTEGRADO: 'True', OPERACAO: c.OPERACAO, OBSERVACAO: '', ID_BFI: '', ID_BFI_SEQ_LCTOS: '', ID_MOV_DESP: c.ID_MOV_DESP, DESCRICAO: c.DESCRICAO});
          this.somaValores(1, this.vOutrosMov);
          }
        })
      }  
    });
    
  }

  ngOnInit() {
    this.datasnap.loginAutomatico();
    this.datasnap.openLoading(false);
    this.getTabelas();

  }

  getTabelas() {

  }

  loggout(tempo: number) {
    setTimeout(() => {
      this.router.navigate(['/Login']);
    }, tempo);
  }

  getDescricao(temp: string) {
    this.vDF818.forEach(v => {
      if (v.CODIGO == temp) {
        this.inpObsMov = v.OBS;
        this.newOutMov.OPERACAO = v.TIPO;
      }
    });
  }


  getAgencia(temp: string) {
    this.vCodigoAgencia.forEach(v => {
      if (v.AGENCIA == temp) {
        this.inpCodAgNome = v.NOME;
      }
    });
  }

  getDadosBanco(temp: string) {
    this.vBanco.forEach(b => {
      if (b.ID == temp) {
        this.newMovBanco.CONTA = b.CONTA;
        this.newMovBanco.AGENCIA = b.AG;
        this.newMovBanco.BANCO = b.BANCO;
      }
    });
  }



  salvaDadosBanco() {
    this.newMovBanco.EMP = this.usuarioLogado.EMP;
    this.newMovBanco.ID_CAIXA = '0';

    this.vSeq = 0;
    if (this.vBancoMov.length == 0) {
      this.newMovBanco.SEQ_DEPOSITOS = (this.vSeq + 1).toString();
    } else if (this.vBancoMov.length > 0) {
      this.vBancoMov.forEach(p => {
        this.vSeq = Math.max(parseInt(p.SEQ_DEPOSITOS));
        this.newMovBanco.SEQ_DEPOSITOS = (this.vSeq + 1).toString();
      });
    }

    this.vBancoMov.push({ EMP: this.newMovBanco.EMP, ID_CAIXA: this.newMovBanco.ID_CAIXA, SEQ_DEPOSITOS: this.newMovBanco.SEQ_DEPOSITOS, CODIGO: '', VALOR: this.newMovBanco.VALOR, DATA: this.newMovBanco.DATA, BANCO: this.newMovBanco.BANCO, AGENCIA: this.newMovBanco.AGENCIA, CONTA: this.newMovBanco.CONTA, OBSERVACAO: this.newMovBanco.OBSERVACAO });
    this.somaValores(2, this.vBancoMov);

    this.LimpaSalvaDadosBanco();
  }


  getDadosRequisicao(temp: string) {
    this.vListaRequisicao.forEach(b => {
      if (b.CLIENTE == temp) {
        this.newRequisao.NOME = b.NOME;
      }
    });
  }


  salvaDadosRequisicao() {
    this.newRequisao.EMP = this.usuarioLogado.EMP;
    this.newRequisao.ID_CAIXA = '0';
    this.vSeq = 0;


    if (this.vRequisicao.length == 0) {
      this.newRequisao.SEQ_REQUISICAO = (this.vSeq + 1).toString();
    } else if (this.vRequisicao.length > 0) {
      this.vRequisicao.forEach(p => {
        this.vSeq = Math.max(parseInt(p.SEQ_REQUISICAO));
        this.newRequisao.SEQ_REQUISICAO = (this.vSeq + 1).toString();
      });
    }


    this.vRequisicao.push({ EMP: this.newRequisao.EMP, ID_CAIXA: this.newRequisao.ID_CAIXA, SEQ_REQUISICAO: this.newRequisao.SEQ_REQUISICAO, VALOR: this.newRequisao.VALOR, DATA: this.newRequisao.DATA, SERIE: '', NUMERO: this.newRequisao.NUMERO, CLIENTE: this.newRequisao.CLIENTE, NOME: this.newRequisao.NOME, OBSERVACAO: this.newRequisao.OBSERVACAO });
    this.somaValores(3, this.vRequisicao);
    this.LimpaSalvaDadosRequisicao();
  }


  salvaSaldoAtual() {


    this.newMovSaldoCaixa.EMP = this.usuarioLogado.EMP;
    this.newMovSaldoCaixa.ID_CAIXA = '0';
    this.newMovSaldoCaixa.AGENCIA = this.inpCodAgAgencia;
    this.newMovSaldoCaixa.SALDO_CAIXA = (this.totalLiquido + this.somaConhec + this.vlrSaldoCaixa + this.saldoAnterior) - (this.totOutMov + this.totRequisicaoMov + this.totBancoMov);
    this.newMovSaldoCaixa.TOTAL_PASSAGENS = this.totalLiquido;
    this.newMovSaldoCaixa.TOTAL_OUTROS_MOVIMENTOS = this.totOutMov;
    this.newMovSaldoCaixa.TOTAL_CONHEC = this.somaConhec;
    this.newMovSaldoCaixa.TOTAL_REQUISICOES = this.totRequisicaoMov;
    this.newMovSaldoCaixa.TOTAL_DEPOSITOS = this.totBancoMov;
    this.vMovSaldoCaixa.push({
      EMP: this.newMovSaldoCaixa.EMP, ID_CAIXA: this.newMovSaldoCaixa.ID_CAIXA, AGENCIA: this.newMovSaldoCaixa.AGENCIA, SALDO_CAIXA: this.newMovSaldoCaixa.SALDO_CAIXA, SALDO_CAIXA_ANTERIORES: this.vlrSaldoCaixa, DATA: this.datasnap.getDataCompleta()
      , TOTAL_PASSAGENS: this.newMovSaldoCaixa.TOTAL_PASSAGENS, TOTAL_OUTROS_MOVIMENTOS: this.newMovSaldoCaixa.TOTAL_OUTROS_MOVIMENTOS, TOTAL_REQUISICOES: this.newMovSaldoCaixa.TOTAL_REQUISICOES, TOTAL_CONHEC: this.newMovSaldoCaixa.TOTAL_CONHEC, TOTAL_DEPOSITOS: this.newMovSaldoCaixa.TOTAL_DEPOSITOS
    });
   
  }
   
  LimpaSalvaDados(){
    this.inpSelMov = '';
    this.inpObsMov = '';
    this.newOutMov.SEQ_LCTOS = '';
    this.newOutMov.VALOR = 0;
    this.newOutMov.DATA = '';
    this.newOutMov.OPERACAO = '';
    this.newOutMov.OBSERVACAO = '';
    this.newOutMov.DOCUMENTO  = '';

    }

    LimpaSalvaDadosBanco(){
     this.newMovBanco.VALOR = ''; 
     this.newMovBanco.DATA = '';
     this.newMovBanco.BANCO = ''; 
     this.newMovBanco.AGENCIA = ''; 
     this.newMovBanco.CONTA = '';
     this.newMovBanco.OBSERVACAO = '';
     this.inpBancoID  = '';
  
    }
    
    LimpaSalvaDadosRequisicao(){

     this.newRequisao.SEQ_REQUISICAO = '';
     this.newRequisao.VALOR = '';
     this.newRequisao.DATA = ''; 
     this.newRequisao.NUMERO = ''; 
     this.newRequisao.CLIENTE = ''; 
     this.newRequisao.NOME = ''; 
     this.newRequisao.OBSERVACAO = ''; 
    
    
    }
    

  

  salvaDados() {
    this.newOutMov.EMP = this.usuarioLogado.EMP;
    this.newOutMov.ID_CAIXA = '0';
    
    if (this.newOutMov.OPERACAO == 'E') {
      this.newOutMov.VALOR = this.newOutMov.VALOR * -1;
    }

    this.vSeq = 0;
    if (this.vOutrosMov.length == 0) {
      this.newOutMov.SEQ_LCTOS = (this.vSeq + 1).toString();
    } else if (this.vOutrosMov.length > 0) {
      this.vOutrosMov.forEach(p => {
        this.vSeq = Math.max(parseInt(p.SEQ_LCTOS));
        this.newOutMov.SEQ_LCTOS = (this.vSeq + 1).toString();
      });
    }


    this.vOutrosMov.push({ EMP: this.newOutMov.EMP, ID_CAIXA: this.newOutMov.ID_CAIXA, SEQ_LCTOS: this.newOutMov.SEQ_LCTOS, CODIGO: this.inpSelMov, VALOR: this.newOutMov.VALOR, DATA: this.newOutMov.DATA, DOCUMENTO: '', INTEGRADO: 'False', OPERACAO: this.newOutMov.OPERACAO, OBSERVACAO: this.newOutMov.OBSERVACAO, ID_BFI: '', ID_BFI_SEQ_LCTOS: '', ID_MOV_DESP: '', DESCRICAO: this.inpObsMov });
    this.somaValores(1, this.vOutrosMov);
    

    this.LimpaSalvaDados();
  }


  saveGeral() {

    this.valResultado = (this.totalLiquido + this.somaConhec + this.vlrSaldoCaixa + this.saldoAnterior) - (this.totOutMov + this.totRequisicaoMov + this.totBancoMov);

   // if (this.valResultado > 1 || this.valResultado < -1) {
    //  this.tstErro = true;

    //  setTimeout(() => {
    //    this.tstErro = false;
    // }, 5000);
   // } else {
      this.datasnap.getDG85(this.usuarioLogado.EMP, 'CAIXA', 'U', 'WEB').subscribe((data: string[][]) => {
        this.vDG85 = data['result'];
        let vMaster: QDS0748 = new QDS0748();

        vMaster.EMP = this.usuarioLogado.EMP;
        vMaster.USUARIO = this.usuarioLogado.USUARIO;
        vMaster.AGENCIA = this.inpCodAgAgencia;
        vMaster.ID_CAIXA = this.vDG85[0].NUMERO;
        vMaster.DATA = this.datasnap.getDataCompleta();
        vMaster.DATA_FECHAMENTO = this.datasnap.getDataCompleta();
        vMaster.DATA_REC_DCTO = '';

        this.Qmaster.master = vMaster;

        this.salvaSaldoAtual();

        this.vCaixa.forEach(c => {
          c.ID_CAIXA = this.vDG85[0].NUMERO;
        });


        this.vEncomendas.forEach(e => {
          e.ID_CAIXA = this.vDG85[0].NUMERO;
        });

        this.vOutrosMov.forEach(e => {
          e.ID_CAIXA = this.vDG85[0].NUMERO;
        });

        this.vBancoMov.forEach(e => {
          e.ID_CAIXA = this.vDG85[0].NUMERO;
        });

        this.vRequisicao.forEach(e => {
          e.ID_CAIXA = this.vDG85[0].NUMERO;
        });

        this.vMovSaldoCaixa.forEach(e => {
          e.ID_CAIXA = this.vDG85[0].NUMERO;
        });


        this.Qmaster.detail1 = this.vOutrosMov;
        this.Qmaster.detail2 = this.vBancoMov;
        this.Qmaster.detail3 = this.vCaixa;
        this.Qmaster.detail4 = this.vRequisicao;
        this.Qmaster.detail5 = this.vEncomendas;
        this.Qmaster.detail6 = this.vMovSaldoCaixa;



        let vRetorno: Array<Retorno> = [];
        this.datasnap.saveDados('I', this.Qmaster).subscribe((data: string[][]) => {
          vRetorno = data['result'];

          if (vRetorno[0].RETORNO == 'OK') {
            this.printPdf(this.vDG85[0].NUMERO);
            
            //this.refresh();

          }
        })
      });
    //}
  }


  removeBanco(temp: MOVBANCO) {
    const index = this.vBancoMov.indexOf(temp);
    this.vBancoMov.splice(index, 1);
    this.somaValores(2, this.vBancoMov);

  }

  removeDocumento(temp: OUTROMOVIMENTO) {
    const index = this.vOutrosMov.indexOf(temp);
    this.vOutrosMov.splice(index, 1);
    this.somaValores(1, this.vOutrosMov);
  }
  removeRequisicao(temp: MOVREQUISICAO) {
    const index = this.vRequisicao.indexOf(temp);
    this.vRequisicao.splice(index, 1);
    this.somaValores(3, this.vRequisicao);

  }
  somaValores(tipo: number, array: any) {
    let vTotal = 0;
    this.vSubTotal = [];

    if (tipo == 1) {
      for (let i = 0; array.length > i; i++) {
        vTotal = vTotal + parseFloat(array[i].VALOR);
      }

      this.vSubTotal.push({ TIPO: '1', DESCRICAO: 'OUTROS MOVIMENTOS', VLR_TOT: vTotal });
      this.vSubTotal.forEach(s => {
        if (s.TIPO == '1') {
          this.totOutMov = s.VLR_TOT;
        }
      })
    }
    if (tipo == 2) {
      for (let i = 0; array.length > i; i++) {
        vTotal = vTotal + parseFloat(array[i].VALOR);
      }

      this.vSubTotal.push({ TIPO: '2', DESCRICAO: 'BANCOS', VLR_TOT: vTotal });
      this.vSubTotal.forEach(s => {
        if (s.TIPO == '2') {
          this.totBancoMov = s.VLR_TOT;
        }
      })
    }
    if (tipo == 3) {
      for (let i = 0; array.length > i; i++) {
        vTotal = vTotal + parseFloat(array[i].VALOR);
      }

      this.vSubTotal.push({ TIPO: '3', DESCRICAO: 'REQUISICAO', VLR_TOT: vTotal });
      this.vSubTotal.forEach(s => {
        if (s.TIPO == '3') {
          this.totRequisicaoMov = s.VLR_TOT;
        }
      })
    }
  }

  printPdf(idCaixa: string) {
    if (idCaixa != '' && idCaixa != null && idCaixa != undefined) {
      this.datasnap.onPrintPdf(this.usuarioLogado.EMP, this.usuarioLogado.USUARIO, idCaixa);
    }
  }

  refresh(): void {
    window.location.reload();
  }

}