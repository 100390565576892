import { OUTROMOVIMENTO } from './outroMovimento';
import { BANCO } from './banco';
import { Caixa } from './caixa';
import { MOVREQUISICAO } from './movRequisicao';
import { ENCOMENDAS } from './encomendas';
import { SALDOCAIXA } from './saldoCaixa';
import { MOVBANCO } from './movBanco';
import { QDS0748 } from './qds0748';
import { CaixaMov } from './caixaMov';
import { MOVSALDOCAIXA } from './movSaldoCaixa';


export class qMaster {

    sqlMaster: string;
    master: QDS0748;

    sqlDetail1: string;
    keyDetail1: string;
    detail1: Array<OUTROMOVIMENTO>;

    sqlDetail2: string;
    keyDetail2: string;
    detail2: Array<MOVBANCO>;

    sqlDetail3: string;
    keyDetail3: string;
    detail3: Array<Caixa>;

    
    sqlDetail4: string;
    keyDetail4: string;
    detail4: Array<MOVREQUISICAO>;

    sqlDetail5: string;
    keyDetail5: string;
    detail5: Array<ENCOMENDAS>;
    
    sqlDetail6: string;
    keyDetail6: string;
    detail6: Array<MOVSALDOCAIXA>;

}