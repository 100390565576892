export class MOVSALDOCAIXA {
    EMP: string;
    ID_CAIXA: string;
    AGENCIA: string;
    SALDO_CAIXA: number;
    SALDO_CAIXA_ANTERIORES: number;
    DATA: string;
    TOTAL_PASSAGENS: number;
    TOTAL_OUTROS_MOVIMENTOS: number;
    TOTAL_REQUISICOES: number;
    TOTAL_CONHEC: number;
    TOTAL_DEPOSITOS: number;
    }   