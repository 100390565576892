import { Component, OnInit } from '@angular/core';
import { Router, Data, NavigationExtras } from '@angular/router';
import { DatasnapProvider } from 'src/provider/datasnap';
import { Usuario } from 'src/models/usuario';
import { environment } from 'src/environments/environment';
//import { PassThrough } from 'stream';

@Component({
  selector: 'app-login',
  templateUrl: './login.html',
  styleUrls: ['./login.css']
})
export class LoginComponent implements OnInit {
  ano: number;
  versao: string = this.datasnap.versao;
  vLogo: string = environment.config.conexao.logoUrl;
  empresa: string;
  inpUser: string;
  inpSenha: string;
  usuario: Array<Usuario>;

  constructor(private router: Router, public datasnap: DatasnapProvider) {
    this.empresa = environment.config.empresa.empresa;
  }

  ngOnInit() {
    this.ano = new Date().getFullYear();
    let vsql = 'select emp, nome from dg0001';
    let vp = ''
    this.datasnap.getSQL(this.empresa, encodeURIComponent(vsql), vp).subscribe((data: string[][]) => {

    })
  }

  login() {
    let user = this.inpUser;
    let pass = this.inpSenha;

    if (user == undefined || user == null) {
      this.datasnap.alertaLogin(2, 'Registro inválido.');
    } else if (pass == undefined || pass == null) {
      this.datasnap.alertaLogin(2, 'Senha inválida.');
    } else {
      let titulo = 'LOGIN_PORTAL';
      let param = '{"&I_EMP": "' + this.empresa + '" , "&I_REGISTRO": "' + user + '"}';

      this.datasnap.getUsuario(this.empresa, user, pass).subscribe((data: string[][]) => {
        this.usuario = data['result'];
        if (this.usuario.length > 0) {
          this.usuario.forEach(u => {
            if (u.USUARIO != user.toString()) {
              this.datasnap.alertaLogin(2, 'Registro não cadastrado.');
            } else if (u.SENHA != pass) {
              this.datasnap.alertaLogin(2, 'Senha inválida.');
            } else if (u.USUARIO == user.toString() && u.SENHA == pass) {
              this.realizarLogin(u);
            }
          });
        } else {
          this.datasnap.alertaLogin(2, 'Registro não cadastrado.');
        }
      });
    }
  }

  realizarLogin(usuario: Usuario) {
    this.datasnap.login(usuario);
  }

}
