<div class="container-fluid">
    <section id="sectionForm">
        <div class="headerPage">
        <b>Fechamento Caixa </b>
        </div>
    </section>
    <section>
        <div>
            <br>
            <form class="form-inline">
                <div class="form-group mx-sm-3 mb-2">   
                    <label    > Data:</label>
                </div>   
                <div class="form-group mx-sm-3 mb-2">
                  <input type="date" class="form-control form-control-sm" name="inpDataCaixa" [(ngModel)]="this.inpDataCaixa">
                </div>
                <div class="form-group mx-sm-3 mb-2">   
                    <label    > Agencia:</label>
                </div>   
                <div class="form-group mx-sm-3 mb-2">
                  <select class="form-control form-control-sm" name="inpCodAgAgencia" [(ngModel)]='inpCodAgAgencia' (change)="getAgencia(inpCodAgAgencia)" required>
                    <option *ngFor="let d of vCodigoAgencia" value="{{d.AGENCIA}}">{{d.AGENCIA}} - {{d.NOME}}</option>
                  </select>
                </div> 
                <div class="form-group mx-sm-3 mb-2"> 
                    <button class="btn btn-primary" (click)="carregaCaixa(this.inpDataCaixa)"  >Pesquisa</button>
                </div>    
            </form>
        </div>  
    </section>   
    <section>
        <div class="table-responsive-sm">
            <div  class="ScrollStyle">
            <table class="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Usuário</th>
                        <th>Data</th>
                        <th>Grupo</th>
                        <th>Tipo Linha</th>
                        <th>Tarifa</th>
                        <th>Seguro</th>
                        <th>Taxa</th>
                        <th>Pedágio</th>
                        <th>Quant.</th>
                        <th>Devolução</th>
                        <th>Cancelado</th>
                        <th>Total</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let e of vCaixa" class="table table-striped table-sm">
                        <td>{{e.USUARIO_SRVP}}</td>
                        <td>{{e.DATA }}</td>
                        <td>{{e.D_GRUPO_P}}</td>
                        <td>{{e.D_ORGAO}}</td>
                        <td>{{e.VALOR_TARIFA | currency: 'BRL'}}</td>
                        <td>{{e.VALOR_SEGURO | currency: 'BRL'}}</td>
                        <td>{{e.VALOR_TX | currency: 'BRL'}}</td>
                        <td>{{e.VALOR_PEDAGIO | currency: 'BRL'}}</td>
                        <td>{{e.QUANT}}</td>
                        <td>{{e.DEVOLUCAO | currency: 'BRL'}}</td>
                        <td>{{e.CANCELADO | currency: 'BRL'}}</td>
                        <td>{{e.TOTAL | currency: 'BRL'}}</td>
                        
                     </tr>
                     <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{{this.tTarifa| currency: 'BRL'}}</td>
                        <td>{{this.tSeguro| currency: 'BRL'}}</td>
                        <td>{{this.tTaxa | currency: 'BRL'}}</td>
                        <td>{{this.tPedagio | currency: 'BRL'}}</td>
                        <td>{{this.tQuant }}</td>
                        <td>{{this.TDevolucao | currency: 'BRL'}}</td>
                        <td>{{this.tCancelado | currency: 'BRL'}}</td>
                        <td>{{this.totalPassagens | currency: 'BRL'}}</td>
                    </tr>
                        

                </tbody>
            </table>
            </div>
        </div>
        <hr>
    </section>
    <section>
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Outros Movimentos</label>
                            <select class="form-control form-control-sm" name="inpSelMov" [(ngModel)]='inpSelMov'
                                (change)="getDescricao(inpSelMov)" required>
                                <option *ngFor="let d of vDF818" value="{{d.CODIGO}}">{{d.CODIGO}} - {{d.DESCRICAO}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Observações</label>
                            <input type="text" class="form-control form-control-sm" name="inpObsMov"
                                [(ngModel)]="inpObsMov" readonly>
                            
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Operação</label>
                                <input type="text" class="form-control form-control-sm" name="inpTipoMov"
                                [(ngModel)]="this.newOutMov.OPERACAO" readonly>    
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Data</label>
                            <input type="date" class="form-control form-control-sm" name="inpDataMov"
                                [(ngModel)]="this.newOutMov.DATA">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Documento</label>
                            <input type="number" class="form-control form-control-sm" name="inpDocMov"
                                [(ngModel)]="this.newOutMov.DOCUMENTO">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Observações</label>
                            <input type="text" class="form-control form-control-sm" name="inpComenMov"
                                [(ngModel)]="this.newOutMov.OBSERVACAO">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Valor</label>
                            <input currencyMask  type="text" class="form-control form-control-sm" name="inpValorMov"
                                [(ngModel)]="this.newOutMov.VALOR" required>
                        </div>
                    </div>
                    <DIV class="form-row">
                        <button class="btn btn-primary" (click)="salvaDados()" >Adicionar </button>&nbsp;&nbsp;&nbsp;&nbsp;
                        
                        <button class="btn btn-info" (click)="LimpaSalvaDados()" >Limpa Dados</button>

                    </DIV>
                    
                </div>
                <div class="col-md-6"  >
                    <h6>Lançamentos - Outros Movimentos</h6>
                    <div class="ScrollStyle2">
                    <table class="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Descrição</th>
                                <th>Operação</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let e of vOutrosMov" class="table table-striped table-sm">
                                <td>{{e.DATA | date: "dd/MM/yyyy"}}</td>
                                <td>{{e.DESCRICAO}}</td>
                                <td>{{e.OPERACAO}}</td>
                                <td>{{e.VALOR | currency: 'BRL'}}</td>
                                <td style="text-align: center">
                                    <button class="btn btn-danger btn-xs" (click)="removeDocumento(e)"
                                        data-toggle="tooltip" data-placement="bottom" title="Cancelar" [disabled]="e.INTEGRADO == 'True'"><i
                                            class="fas fa-times-circle"></i></button>
                                </td>
                            </tr>
                            <tr *ngIf="this.totOutMov > 0">
                                <td>TOTAL:</td>
                                <td></td>
                                <td>{{this.totOutMov | currency: 'BRL'}}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    
                        <div class="form-group col-lg-6">
                        </div>
                    </table>
                </div>   
                </div>
            </div>
        </div>
    </section>
    <hr>
    <section>
        <div>
            <div class="row">
                <div class="col-md-6">

                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Data</label>
                            <input type="date" class="form-control form-control-sm" name="inpBancoData"
                                [(ngModel)]="this.newMovBanco.DATA">
                        </div>

                        <div class="form-group col-lg-6">
                            <label>Banco</label>
                            <select class="form-control form-control-sm" name="inpBancoID" [(ngModel)]='inpBancoID'
                                (change)="getDadosBanco(inpBancoID)" required>
                                <option *ngFor="let b of vBanco" value="{{b.ID}}">{{b.BANCO}} - {{b.AG}} - {{b.CONTA}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Agência</label>
                            <input type="text" class="form-control form-control-sm" name="inBancoBC"
                                [(ngModel)]="this.newMovBanco.BANCO" hidden>
                            <input type="text" class="form-control form-control-sm" name="inpBancoAG"
                                [(ngModel)]="this.newMovBanco.AGENCIA" readonly>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Conta</label>
                            <input type="text" class="form-control form-control-sm" name="inpBancoConta"
                                [(ngModel)]="this.newMovBanco.CONTA" readonly>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Observações</label>
                            <input type="text" class="form-control form-control-sm" name="inpBancoObs"
                                [(ngModel)]="this.newMovBanco.OBSERVACAO">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Valor</label>
                            <input currencyMask type="text" class="form-control form-control-sm" name="inpBancoValor"
                                [(ngModel)]="this.newMovBanco.VALOR" required>
                        </div>
                    </div>
                    <DIV class="form-row">
                        <button class="btn btn-primary" (click)="salvaDadosBanco()">Adicionar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        
                        <button class="btn btn-info" (click)="LimpaSalvaDadosBanco()" >Limpa Dados</button>

                    </DIV>
                </div>

                <div class="col-md-6">
                    <h6>Lançamentos - Depósitos</h6>
                    <table class="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Banco</th>
                                <th>Agencia</th>
                                <th>Conta</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let e of vBancoMov" >
                                <td>{{e.DATA | date: "dd/MM/yyyy"}}</td>
                                <td>{{e.BANCO}}</td>
                                <td>{{e.AG}}</td>
                                <td>{{e.CONTA}}</td>
                                <td>{{e.VALOR | currency: 'BRL'}}</td>
                                <td style="text-align: center">
                                    <button class="btn btn-danger btn-xs" (click)="removeBanco(e)" data-toggle="tooltip"
                                        data-placement="bottom" title="Cancelar"><i
                                            class="fas fa-times-circle"></i></button>
                                </td>
                            </tr>
                            <tr *ngIf="this.totBancoMov > 0">
                                <td>TOTAL:</td>
                                <td></td>
                                <td>{{this.totBancoMov | currency: 'BRL'}}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <hr>
    <section>
        <div>
            <div class="row">
                <div class="col-md-6">

                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Data</label>
                            <input type="date" class="form-control form-control-sm" name="inpRequisicaoData"
                                [(ngModel)]="this.newRequisao.DATA">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Número</label>
                            <input type="number" class="form-control form-control-sm" name="inpRequisicaoNumero"
                                [(ngModel)]="this.newRequisao.NUMERO">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Cliente</label>
                            <select class="form-control form-control-sm" name="inpRequisicaoCNPJ"
                                [(ngModel)]='this.newRequisao.CLIENTE' (change)="getDadosRequisicao(inpRequisicaoCNPJ)"
                                required>
                                <option *ngFor="let b of vListaRequisicao" value="{{b.CNPJ}}"> {{b.CNPJ}} - {{b.NOME}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Nome</label>
                            <input type="text" class="form-control form-control-sm" name="inpRequisicaoNome"
                                [(ngModel)]="this.newRequisao.NOME" readonly>
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Observações</label>
                            <input type="text" class="form-control form-control-sm" name="inpRequisicaoOBS"
                                [(ngModel)]="this.newRequisao.OBSERVACAO">
                        </div>
                        <div class="form-group col-lg-6">
                            <label>Valor</label>
                            <input currencyMask type="text" class="form-control form-control-sm" name="inpRequisicaoValor"
                                [(ngModel)]="this.newRequisao.VALOR">
                        </div>
                    </div>
                    <DIV class="form-row">
                        <button class="btn btn-primary" (click)="salvaDadosRequisicao()">Adicionar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        
                        <button class="btn btn-info" (click)="LimpaSalvaDadosRequisicao()" >Limpa Dados</button>

                    </DIV>
                </div>

                <div class="col-md-6">
                    <h6>Lançamentos - Requisições</h6>
                    <table class="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Número</th>
                                <th>Cliente</th>
                                <th>Nome</th>
                                <th>Valor</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let e of vRequisicao">
                                <td>{{e.DATA | date: "dd/MM/yyyy"}}</td>
                                <td>{{e.NUMERO}}</td>
                                <td>{{e.CLIENTE}}</td>
                                <td>{{e.NOME}}</td>
                                <td>{{e.VALOR | currency: 'BRL'}}</td>
                                <td style="text-align: center">
                                    <button class="btn btn-danger btn-xs" (click)="removeRequisicao(e)"
                                        data-toggle="tooltip" data-placement="bottom" title="Cancelar"><i
                                            class="fas fa-times-circle"></i></button>
                                </td>
                            </tr>
                            <tr *ngIf="this.totRequisicaoMov > 0">
                                <td>TOTAL:</td>
                                <td></td>
                                <td>{{this.totRequisicaoMov | currency: 'BRL'}}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <br>
    <hr>
    <section>
        <div>
            <div class="row" >
                <div class="col-6">
                    <h6>Resumo</h6>
                    <div>
                        <table class="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Soma Passagens Agência</td>
                                    <td>{{this.totalLiquido | currency: 'BRL'}}</td>
                                </tr>
                                <tr>
                                    <td>Soma Outros Movimentos</td>
                                    <td>{{this.totOutMov | currency: 'BRL'}}</td>
                                </tr>
                                <tr>
                                    <td>Soma Depósitos</td>
                                    <td>{{this.totBancoMov | currency: 'BRL'}}</td>
                                </tr>
                                <tr>
                                    <td>Soma Requisições Passagens</td>
                                    <td>{{this.totRequisicaoMov | currency: 'BRL'}}</td>
                                </tr>
                                <tr>
                                    <td>Soma Conhecimentos</td>
                                    <td>{{this.somaConhec | currency: 'BRL'}}</td>
                                </tr>
                                <tr>
                                    <td>Saldo Caixa Anterior</td>
                                    <td>{{this.vlrSaldoCaixa  | currency: 'BRL'}}</td>
                                </tr>
                                <tr>
                                    <td>Resultado</td>
                                    <td>{{(this.totalLiquido + this.somaConhec + this.vlrSaldoCaixa + this.saldoAnterior  ) - (this.totOutMov + this.totRequisicaoMov + this.totBancoMov)  | currency: 'BRL'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-6">
                    <h6>Conhecimentos</h6>
                    <div class="ScrollStyle">
                        <table class="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Série</th>
                                    <th>Número</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                             <tbody>
                                    <tr *ngFor="let e of vEncomendas">
                                        <td>{{e.SERIE}}</td>
                                        <td>{{e.CONHEC}}</td>
                                                                    
                                        <td>{{e.TOTAL | currency: 'BRL'}}</td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="this.tstErro == true">
            <div class="col-12">
                <div class="alert alert-danger" role="alert">
                    O Saldo deve ser 0 ou entre R$1,00 e -R$1,00!
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-sm btn-primary" (click)="saveGeral()">Salvar Dados</button>
            </div>
        </div>

    </section>


</div>