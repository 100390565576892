export class MOVREQUISICAO {
    EMP: string;
    ID_CAIXA: string;
    SEQ_REQUISICAO: string;
    VALOR: string;
    DATA: string;
    SERIE: string;
    NUMERO: string;
    CLIENTE: string;
    NOME: string;
    OBSERVACAO: string;
}