import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { qMaster } from 'src/models/qMaster';
import { Usuario } from 'src/models/usuario';
import { BehaviorSubject, Observable } from 'rxjs';
import { RetornoRel } from 'src/models/retornoRel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
    'Authorization': 'Basic TkdTOk5HUw=='
  })
};

@Injectable({
  providedIn: 'root'
})
export class DatasnapProvider {
  public versao: string;
  private baseUrl: string;
  public mostraMenu: boolean = false;
  public textAlert: String;
  public typeAlert: String;
  public typeShow: string;
  public textShow: string;
  public Alert: boolean = false;
  public AlertLogin: boolean = false;
  public loader: boolean = false;

  public empresa: string = '';
  public nomeEmpresa: string = '';
  public usuario: string = '';
  public nomeUsuario: string = '';
  public usuarioLetra: string = '';
  
  public user: BehaviorSubject<Usuario> = new BehaviorSubject<Usuario>(null);

  private PATH_SQLCONSULTA = 'GetSqlConsulta/';
  private PATH_SQL = 'GetPreSqlConsulta/';
  private PATH_SQL2 = 'GetPreSqlConsultaV2/';
  private PATH_USUARIO = 'GetUsuarioLogin/';
  private PATH_USUARIO_CAIXA = 'GetUsuarioLoginCaixa/';
  private PATH_DG85 = 'GetDG85/';
  private PATH_PUT_JSON = 'PutDadosJson/';
  private PATH_POST_JSON = 'PutDadosJsonObj/';
  private PATH_PDF_REL = 'GetPdfRelatorio/';
  vCodigoAgencia: any;
  //GetUsuarioLogin

  get isUsuario() {
    return this.user.asObservable();
  }

  constructor(private router: Router, public http: HttpClient) {
    this.versao = '1.0.3';
    this.baseUrl = environment.config.conexao.baseUrl
  }

  login(usuario: Usuario) {
    this.router.navigate(['/Caixa']);
    this.setUsuarioLogado(usuario);
    this.user.next(usuario);
  }

  loggout() {
    localStorage.removeItem('usuario');
    this.montaManu(false);
    this.router.navigate(['/Login'])
  }

  setUsuarioLogado(usuario: Usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));

  }

  usuarioLogado: Usuario
  getUsuarioLogado() {
    this.usuarioLogado = JSON.parse(localStorage.getItem('usuario'));
    return this.usuarioLogado;
  }

  alertaLogin(tipo: number, mensagem: String) {
    let tp = tipo;
    let msg = mensagem
    this.textAlert = msg;

    if (tp == 1) {
      this.typeAlert = 'alert-success'
    } else if (tp == 2) {
      this.typeAlert = 'alert-danger'
    }

    this.AlertLogin = !this.AlertLogin;
    setTimeout(() => {
      this.AlertLogin = !this.AlertLogin;
    }, 4000);
  }

  alertas(tipo: number, mensagem: String) {
    this.textAlert = mensagem;
    if (tipo == 1) {
      this.typeAlert = 'alert-success'
    } else if (tipo == 2) {
      this.typeAlert = 'alert-warning'
    }

    this.Alert = !this.Alert;
    setTimeout(() => {
      this.Alert = !this.Alert;
    }, 5000);
  }

  openLoading(temp: boolean) {
    this.loader = temp;
  }

  loginAutomatico() {
    this.openLoading(true);
    this.montaManu(true);
  }

  montaManu(temp: boolean) {
    this.mostraMenu = temp;
  }

  openPage(page: string) {
    this.router.navigate([page]);
  }

  getDataCompleta() {
    let data = new Date();
    let dt = ('0' + data.getDate()).slice(-2)
      + '/'
      + ('0' + (data.getMonth() + 1)).slice(-2)
      + '/'
      + data.getFullYear()
      + ' '
      + data.getHours()
      + ':'
      + ('0' + (data.getMinutes())).slice(-2)
    return dt;
  }

  getConverteDt(dt: string) {
    let data = new Date(dt);
    let newDate = ('0' + data.getUTCDate()).slice(-2) + '/' + ('0' + (data.getUTCMonth() + 1)).slice(-2) + '/' + data.getUTCFullYear();
    return newDate;
  }


  getSQL(emp: string, sql: string, parametros: string) {
    return this.http.get(this.baseUrl + this.PATH_SQLCONSULTA + emp + '/' + sql + '/' + parametros, httpOptions);
  }

  getSQL2(emp: string, titulo: string, parametros: string) {
    return this.http.get(this.baseUrl + this.PATH_SQL + emp + '/' + titulo + '/' + parametros, httpOptions);
  }

  getSQL3(emp: string, titulo: string, parametros: string) {
    return this.http.get(this.baseUrl + this.PATH_SQL2 + emp + '/' + titulo + '/' + parametros, httpOptions);
  }

  getUsuario(emp: string, usuario: string, senha: string) {
    return this.http.get(this.baseUrl + this.PATH_USUARIO_CAIXA + emp + '/' + usuario + '/' + senha, httpOptions);
  }

  getDG85(emp: string, sistema: string, numeracao: string, serie: string) {
    return this.http.get(this.baseUrl + this.PATH_DG85 + emp + '/' + sistema + '/' + numeracao + '/' + serie, httpOptions);
  }

  getRelatorioPdf(parametros: string) {
    return this.http.get(this.baseUrl + this.PATH_PDF_REL + parametros, httpOptions);
  }

  saveDados(op: string, objeto: qMaster) {

    objeto.sqlMaster = 'select ds748.*, ds748.rowid from DS0748 ds748 where ds748.emp = :I_EMP and ds748.id_caixa = :I_ID_CAIXA';


    objeto.sqlDetail1 = 'SELECT DS7482.*, DS7482.ROWID FROM DS07482 DS7482 WHERE DS7482.EMP = :I_EMP AND DS7482.ID_CAIXA =:I_ID_CAIXA';
    objeto.keyDetail1 = 'SEQ_LCTOS';

    objeto.sqlDetail2 = 'SELECT DS7483.*, DS7483.ROWID FROM DS07483 DS7483 WHERE DS7483.EMP = :I_EMP AND DS7483.ID_CAIXA =:I_ID_CAIXA';
    objeto.keyDetail2 = 'SEQ_DEPOSITOS';

    objeto.sqlDetail3 = 'SELECT DS7484.*, DS7484.ROWID FROM DS07484 DS7484 WHERE DS7484.EMP = :I_EMP AND DS7484.ID_CAIXA =:I_ID_CAIXA';
    objeto.keyDetail3 = 'ID_MOV_RJ';

    objeto.sqlDetail4 = 'SELECT DS7485.*, DS7485.ROWID FROM DS07485 DS7485 WHERE DS7485.EMP = :I_EMP AND DS7485.ID_CAIXA =:I_ID_CAIXA';
    objeto.keyDetail4 = 'SEQ_REQUISICAO';

    objeto.sqlDetail5 = 'SELECT DS7488.*, DS7488.ROWID FROM DS07488 DS7488 WHERE DS7488.EMP = :I_EMP AND DS7488.ID_CAIXA =:I_ID_CAIXA';
    objeto.keyDetail5 = 'SEQ_CTE';


    objeto.sqlDetail6 = 'SELECT DS7489.*, DS7489.ROWID FROM DS07489 DS7489 WHERE DS7489.EMP = :I_EMP AND DS7489.ID_CAIXA =:I_ID_CAIXA';
    //objeto.keyDetail6 = 'ID_CAIXA';

    //let arqJson = encodeURIComponent(JSON.stringify(objeto));

    const arqJson = JSON.stringify(objeto);
    return this.http.post(this.baseUrl + this.PATH_POST_JSON + op, arqJson, httpOptions);
  }

  onPrintPdf(emp: string, usuario: string, idCaixa: string) {
    let vRetorno: Array<RetornoRel> = [];
    let pRel = '{"Programa":"FS57484", "Usuario":"' + usuario + '", "DirArquivo":"c:/ngs/temp/", "Empresa":"' + emp + '", "NUMERO":"' + idCaixa + '"}';
    this.getRelatorioPdf(encodeURIComponent(pRel)).subscribe((data: string[][]) => {
      vRetorno = data['result'];
      if (vRetorno[0].RETORNO == 'OK') {
        let i = this.baseUrl.indexOf('/datasnap');
        let newBase = this.baseUrl.substr(0, i);
        let link = newBase + vRetorno[0].LINK;
        window.open(link, '_system');
        window.location.reload();
      }
    });
  }


}