import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/pages/login/login';
import { CaixaFechadoComponent } from 'src/pages/caixa-fechado/caixa-fechado.component';
import { HomeComponent } from 'src/pages/home/home';


const routes: Routes = [
  { path: 'Login', component: LoginComponent },
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  { path: 'CaixaFechados', component: CaixaFechadoComponent },
  { path: 'Caixa', component: HomeComponent },
  { path: 'Home', component: HomeComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
