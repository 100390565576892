export class OUTROMOVIMENTO {
    EMP: string;
    ID_CAIXA: string;
    SEQ_LCTOS: string;
    CODIGO: string;
    VALOR: number;
    DATA: string;
    DOCUMENTO: string;
    INTEGRADO: string;
    OPERACAO: string;
    OBSERVACAO: string;
    ID_BFI: string;
    ID_BFI_SEQ_LCTOS: string;
    ID_MOV_DESP: string;
    DESCRICAO: string;
    
}   