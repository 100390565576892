<div class="container-fluid">
  <section id="sectionForm">
      <div class="headerPage">
      <b>Consulta Caixas Fechados </b>
      </div>
  </section>
  <section>
      <div>
        <br>
          <form class="form-inline">
              <div class="form-group mx-sm-3 mb-2">   
                  <label> Data Inicial:</label>
              </div>   
              <div class="form-group mx-sm-3 mb-2">
                <input type="date" class="form-control form-control-sm" name="inpDataI" [(ngModel)]="this.inpDataI">
              </div>
              <div class="form-group mx-sm-3 mb-2">   
                <label> Data Inicial:</label>
            </div>   
            <div class="form-group mx-sm-3 mb-2">
              <input type="date" class="form-control form-control-sm" name="inpDataF" [(ngModel)]="this.inpDataF">
            </div>
            <div class="form-group mx-sm-3 mb-2">
              <select class="form-control form-control-sm" name="inpCodAgAgencia" [(ngModel)]='inpCodAgAgencia' (change)="getAgencia(inpCodAgAgencia)" required>
                <option *ngFor="let d of vCodigoAgencia" value="{{d.AGENCIA}}">{{d.AGENCIA}} - {{d.NOME}}</option>
              </select>
            </div> 
              <div class="form-group mx-sm-3 mb-2"> 
                    <button class="btn btn-primary"  (click)="carregaCaixaFechado(this.inpDataI, this.inpDataF)">Pesquisa</button>
                    
              </div>    
          </form>
      </div>  
  </section>   
<br>
<section>
  <div class="col-6">
    <h6>Caixas Fechados</h6>
    <div>
        <table class="table table-striped table-sm">
            <thead>
                <tr>
                    <th>Número Caixa</th>
                    <th>Agência</th>
                    <th>Data Fechamento</th>
                </tr>
            </thead>
             <tbody>
                    <tr *ngFor="let e of vCaixaFechado">
                        <td>{{e.ID_CAIXA}}</td>
                        <td>{{e.AGENCIA}}</td>
                        <td>{{e.DATA_FECHAMENTO }}</td>
                        <td><button class="btn btn-primary" (click)="printPdf(e.ID_CAIXA)">Reimprimir</button></td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
</div>
</section>
</div>