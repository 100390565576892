export class MOVBANCO {
    EMP: string;
    ID_CAIXA: string;
    SEQ_DEPOSITOS: string;
    CODIGO: string;
    VALOR: string;
    DATA: string;
    BANCO: string;
    AGENCIA: string;
    CONTA: string;
    OBSERVACAO: string;
}