export class IMPORTADORJ {
    EMP: string;
    ID_MOV_DESP: string;
    DATA: string;
    CODIGO: string;
    DESCRICAO: string;
    VALOR: number;
    AGENCIA: string;
    ID_CAIXA: string;
    IMPORTACAO_RJ: string;
    OPERACAO: string;
    
}   