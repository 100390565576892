export class ENCOMENDAS {
EMP :string;
ID_CAIXA:string;
SEQ_CTE:string;
TOTAL:number;
DATA:string;
SEQ_CONHEC:string;
SERIE:string;
NUMERO:string;
OBSERVACAO:string;
SOMA_CONHEC: number;
 }