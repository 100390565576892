<div class="wrapper">
    <nav id="sidebar">
        <div class="sidebar-header">
            <b>Usuário: </b>{{this.usuario}}
        </div>
        <ul class="list-unstyled components">
            <li>
                <a href="#menuCaixa" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Menu</a>
                <ul class="collapse list-unstyled" id="menuCaixa">
                    <li>
                        <a href="#cadCaixa" data-toggle="collapse" aria-expanded="false"
                            class="dropdown-toggle">Movimentos</a>
                        <ul class="collapse" id="cadCaixa">
                            <li>
                                <a routerLink="/Caixa" data-toggle="collapse" aria-expanded="false">Caixa</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#listaReserva" data-toggle="collapse" aria-expanded="false"
                            class="dropdown-toggle">Consultas</a>
                        <ul class="collapse" id="listaReserva">
                            <li>
                                <a routerLink="/CaixaFechados" data-toggle="collapse" aria-expanded="false">Caixas
                                    Fechados</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
    <div class="content">
        <div class="navbar-custom navbar-expand-lg bg-secondary" [hidden]="!datasnap.mostraMenu">
            <div>
                <span class="navbar-brand mb-0 h1">Ngs | Caixa Agência</span>
                <form class="form-inline topbar-rigth-menu float-right mb-0">
                    <button class="btn btn-outline-light my-2 my-sm-0" (click)="onLogout()">
                        SAIR</button>
                </form>
            </div>
        </div>
        <div class="main-alert" [hidden]="!datasnap.Alert">
            <div class="alert {{datasnap.typeAlert}}" role="alert" data-dismiss="alerta">
                <p>{{datasnap.textAlert}}</p>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true" style="color: white">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal" id="modalSobre" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Sobre nós</h5>
                        <button type="button" class="btn btn-outline-secondary btn-sm" data-dismiss="modal"
                            #closeModal4><i class="fas fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <h6><b>Versão:</b> {{datasnap.versao}}</h6>
                        <h6>&#169; {{this.ano}} NGS Nova Geração em Sistemas</h6>
                        <h6><a href="http://ngs.inf.br/" style="color: lightskyblue">www.ngs.inf.br</a></h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="loader justify-content-center" [hidden]="!datasnap.loader">
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark" type="button" disabled>
                <span class="spinner-border" role="status" aria-hidden="true"></span>
                Carregando...
            </button>
        </div>
    </div>
</div>