import { Component } from '@angular/core';
import { DatasnapProvider } from 'src/provider/datasnap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'NgPortalCaixa';
  ano: number;
  usuario: string;

  constructor(public datasnap: DatasnapProvider, private router: Router) {
    this.ano = new Date().getFullYear();
  }

  onLogout() {
    this.datasnap.loggout();
  }

  openPage(page) {

    this.datasnap.openPage(page);
  }

}