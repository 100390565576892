export const environment = {
  production: false,
  config: {
    empresa: {
      empresa: '1'
    },
    conexao: {
      baseUrl: 'http://ngs.santoanjo.com.br:8050/datasnap/rest/TSMNgFiscal/',
      logoUrl: 'https://caixasantoanjo.ngsservicos.com/logo/logo.bmp'
    }
  }
};


